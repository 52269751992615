import { createContext, useContext } from "react";

export const FeedbacksContext = createContext({
  feedbacks: [],
  updateFeedbacks: () => {},
  isEmptyFeedbacks: () => {},
  fetchFeedbacks: () => {},
  FeedbackDeletedHandler: () => {},
  adminFetchFeedbacks: () => {},
  HideFeedbacksSkeleton: true,
  isUpdatedFeedbacks: false,
  FeedbacksError: null,
  setHideFeedbacksSkeleton: () => {}
});

export const useFeedbacksContext = () => useContext(FeedbacksContext);
