import { useState, useCallback, useEffect } from "react";

import { config } from "../../config";
import { useHttpClient } from "../Hooks/http-hook";

const useOffers = () => {
    const [offers, setOffers] = useState();
    const [isUpdatedOffers, setIsUpdatedOffers] = useState(false);
    const [HideOffersSkeleton, setHideOffersSkeleton] = useState(false);
    const { error, sendRequest, clearError } = useHttpClient();

    const updateOffers = (newOffers) => {
        setOffers(newOffers);
        setIsUpdatedOffers(true);
    };

    const isEmptyOffers = useCallback(() => {
        return offers?.length > 0;
    }, [offers]);

    const fetchOffers = useCallback(async () => {
        try {
            const responseData = await sendRequest(
                `${config.API_URL}/offers`
            );
            clearError();
            updateOffers(responseData);
        } catch (err) {
            setIsUpdatedOffers(true);
            console.error(err);
        }
    }, [sendRequest, updateOffers, isUpdatedOffers]);

    const OfferDeletedHandler = useCallback(deletedOfferId => {
        setOffers(prevOffers => prevOffers.filter(offer => offer._id !== deletedOfferId));
    }, [setOffers]);

    return {
        offers,
        updateOffers,
        isUpdatedOffers, 
        fetchOffers,
        HideOffersSkeleton,
        isEmptyOffers,
        OfferDeletedHandler,
        OffersError: error,
        setHideOffersSkeleton,
    };
}

export default useOffers;