import { useState, useCallback, useEffect } from "react";

let logoutTimer;

const useAuth = () => {
    const [token, setToken] = useState(null);
    const [role, setRole] = useState('viewer');
    const [tokenExpirationDate, setTokenExpirationDate] = useState();
    const [userId, setUserId] = useState(false);

    const login = useCallback((uid, token, role, expirationDate) => {
        setToken(token);
        setUserId(uid);
        setRole(role);
        const tokenExpirationDate = expirationDate || new Date(new Date().getTime() + 2000 * 60 * 60);
        setTokenExpirationDate(tokenExpirationDate);
        localStorage.setItem('userData',
            JSON.stringify({
                userId: uid, token: token, expiration: tokenExpirationDate.toISOString(), role: role
            }));
    }, []);

    const logout = useCallback(() => {
        setToken(null);
        setTokenExpirationDate(null);
        setUserId(null);
        setRole('viewer');
        localStorage.removeItem('userData');
    }, []);

    useEffect(() => {
        if (token && tokenExpirationDate) {
            const remainingTime = tokenExpirationDate.getTime() - new Date().getTime();
            logoutTimer = setTimeout(logout, remainingTime);
        } else {
            clearTimeout(logoutTimer);
        }
    }, [token, logout, tokenExpirationDate, role]);

    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem('userData'));
        if (storedData && storedData.token && new Date(storedData.expiration) > new Date()) {
            login(storedData.userId, storedData.token, storedData.role, new Date(storedData.expiration));
        }
    }, [login]);
    return { userId, login, logout, token, role };
}

export default useAuth;