import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

import MainNavigation from './shared/components/Navigation/MainNavigation';
import MainFooter from './shared/components/Footer/MainFooter';
import { AuthContext } from './shared/context/auth-context';
import { SectionsContext } from './shared/context/sections-context';
import { CategoriesContext } from './shared/context/categories-context';
import { FeedbacksContext } from './shared/context/feedbacks-context';
import { OffersContext } from './shared/context/offers-context';
import { RestaurantDetailsContext } from './shared/context/restaurant-details-context';
import useAuth from './shared/context/auth-hook';
import useSections from './shared/context/sections-hook';
import useCategories from './shared/context/categories-hook';
import useFeedbacks from './shared/context/feedbacks-hook';
import useRestaurantDetails from './shared/context/restaurant-details-hook';
import useOffers from './shared/context/offers-hook';
import ScrollToTop from './shared/util/scroll';
import WhatsAppIcon from './shared/components/Whatsapp/whatsapp';
import LoadingSpinner from './shared/components/UIElements/LoadingSpinner';

const Home = React.lazy(() => import('./Home/pages/home'));
const Menu = React.lazy(() => import('./Menu/pages/menu'));
const Contact = React.lazy(() => import('./Contact/pages/contact'));
const About = React.lazy(() => import('./About/pages/about'));
const Gallery = React.lazy(() => import('./Gallery/pages/gallery'));
const Product = React.lazy(() => import('./Product/pages/product'));
const Auth = React.lazy(() => import('./User/pages/Auth'));

const App = () => {
  const {userId, login, logout, token, role} = useAuth();
  const {
    sections,
    updateSections,
    getSectionByName,
    isUpdatedSection,
    fetchSections,
    HideSkeleton,
    sectionError
  } = useSections();
  const {
    categories,
    updateCategories,
    isUpdatedCategories,
    fetchCategories,
    HideCategoriesSkeleton,
    isEmptyItems,
    isEmptyImages,
    ImageDeletedHandler,
    CategoryDeletedHandler,
    ProductDeletedHandler,
    categoriesError,
    getGalleryHomeItem,
    setHideCategoriesSkeleton
  } = useCategories();
  const {
    feedbacks,
    updateFeedbacks,
    isUpdatedFeedbacks,
    fetchFeedbacks,
    HideFeedbacksSkeleton,
    isEmptyFeedbacks,
    FeedbackDeletedHandler,
    FeedbacksError,
    adminFetchFeedbacks,
    setHideFeedbacksSkeleton
  } = useFeedbacks();
  const {
    restaurantDetails,
    fetchRestaurantDetails,
    HideRestaurantDetailsSkeleton,
    isUpdatedRestaurantDetails,
    RestaurantDetailsError,
  } = useRestaurantDetails();
  const {
    offers,
    updateOffers,
    isEmptyOffers,
    fetchOffers,
    OfferDeletedHandler,
    HideOffersSkeleton,
    isUpdatedOffers,
    OffersError,
    setHideOffersSkeleton
  } = useOffers();
  let routes;
  if(!token && role === 'viewer') {
    routes = (
      <Switch>
        <Route path="/" exact><Home HideSkeleton={HideSkeleton}/></Route>
        <Route path="/menu" exact><Menu fetchSections={fetchSections} HideSectionsSkeleton={HideSkeleton}/></Route>
        <Route path="/auth" exact><Auth /></Route>
        <Route path="/product" exact><Product fetchSections={fetchSections} HideSectionsSkeleton={HideSkeleton}/></Route>
        <Route path="/gallery" exact><Gallery fetchSections={fetchSections} HideSectionsSkeleton={HideSkeleton}/></Route>
        <Route path="/about" exact><About fetchSections={fetchSections} HideSectionsSkeleton={HideSkeleton}/></Route>
        <Route path="/contact" exact><Contact fetchSections={fetchSections} HideSectionsSkeleton={HideSkeleton}/></Route>
        <Redirect to="/" />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route path="/" exact><Home fetchSections={fetchSections} HideSkeleton={HideSkeleton}/></Route>
        <Route path="/menu" exact><Menu fetchSections={fetchSections} HideSectionsSkeleton={HideSkeleton}/></Route>
        <Route path="/product" exact><Product fetchSections={fetchSections} HideSectionsSkeleton={HideSkeleton}/></Route>
        <Route path="/gallery" exact><Gallery fetchSections={fetchSections} HideSectionsSkeleton={HideSkeleton}/></Route>
        <Route path="/about" exact><About fetchSections={fetchSections} HideSectionsSkeleton={HideSkeleton}/></Route>
        <Route path="/contact" exact><Contact fetchSections={fetchSections} HideSectionsSkeleton={HideSkeleton}/></Route>
        <Redirect to="/" />
      </Switch>
    );
  }
  

  return (
    <React.Fragment>
      {((isUpdatedRestaurantDetails && HideRestaurantDetailsSkeleton) || RestaurantDetailsError) &&
        <WhatsAppIcon
          whatsapp={restaurantDetails?.whatsapp}
        />
      }
      <AuthContext.Provider value={{
        isLoggedIn: !!token,
        userId: userId,
        role: role,
        token: token,
        login: login,
        logout: logout
      }}>
        <SectionsContext.Provider value={{
          sections,
          updateSections,
          getSectionByName,
          isUpdatedSection,
          sectionError,
          HideSkeleton
        }}>
          <CategoriesContext.Provider value={{
            categories,
            updateCategories,
            ImageDeletedHandler,
            isEmptyItems,
            isEmptyImages,
            fetchCategories,
            CategoryDeletedHandler,
            ProductDeletedHandler,
            HideCategoriesSkeleton,
            isUpdatedCategories,
            categoriesError,
            getGalleryHomeItem,
            setHideCategoriesSkeleton
          }}>
            <FeedbacksContext.Provider value={{
              feedbacks,
              updateFeedbacks,
              isUpdatedFeedbacks,
              fetchFeedbacks,
              HideFeedbacksSkeleton,
              isEmptyFeedbacks,
              FeedbackDeletedHandler,
              FeedbacksError,
              adminFetchFeedbacks,
              setHideFeedbacksSkeleton
            }}>
              <RestaurantDetailsContext.Provider value={{
                restaurantDetails,
                fetchRestaurantDetails,
                HideRestaurantDetailsSkeleton,
                isUpdatedRestaurantDetails,
                RestaurantDetailsError,
              }}>
                <OffersContext.Provider value={{
                  offers,
                  updateOffers,
                  isEmptyOffers,
                  fetchOffers,
                  OfferDeletedHandler,
                  HideOffersSkeleton,
                  isUpdatedOffers,
                  OffersError,
                  setHideOffersSkeleton
                }}>
                  <Router>
                    <ScrollToTop />
                    <MainNavigation />
                    <Suspense fallback={<LoadingSpinner asOverlay />}>
                      <main className='main-container'>
                        {
                          routes
                        }
                      </main>
                    </Suspense>
                    <MainFooter />
                  </Router>
                </OffersContext.Provider>
              </RestaurantDetailsContext.Provider>
            </FeedbacksContext.Provider>
          </CategoriesContext.Provider>
        </SectionsContext.Provider>
      </AuthContext.Provider>
    </React.Fragment>
  );
}

export default App;
