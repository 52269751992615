import { useState, useCallback, useEffect } from "react";

import { config } from "../../config";
import { useHttpClient } from "../Hooks/http-hook";

const useRestaurantDetails = () => {
    const [restaurantDetails, setRestaurantDetails] = useState();  // State to store restaurant details data
    const [isUpdatedRestaurantDetails, setIsUpdatedRestaurantDetails] = useState(false);
    const [HideRestaurantDetailsSkeleton, setHideRestaurantDetailsSkeleton] = useState(false);
    const { error, sendRequest, clearError } = useHttpClient();

    const updateRestaurantDetails = (newRestaurantDetails) => {
        setRestaurantDetails(newRestaurantDetails);
        setIsUpdatedRestaurantDetails(true);
    };

    const fetchRestaurantDetails = useCallback(async () => {
        try {
            const responseData = await sendRequest(
                `${config.API_URL}/details`
            );
            clearError();
            updateRestaurantDetails(responseData);
        } catch (err) {
            setIsUpdatedRestaurantDetails(true);
            console.error(err);
        }
    }, [sendRequest, updateRestaurantDetails, setIsUpdatedRestaurantDetails, clearError]);

    useEffect(() => {
        fetchRestaurantDetails();
        setTimeout(() => {
            setHideRestaurantDetailsSkeleton(true);
        }, 1000);
    }, []);

    return {
        restaurantDetails,
        isUpdatedRestaurantDetails, 
        fetchRestaurantDetails,
        HideRestaurantDetailsSkeleton,
        RestaurantDetailsError: error,
    };
}

export default useRestaurantDetails;