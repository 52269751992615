import aboutImgBackground from '../assets/about-home-background.png';
import aboutImg from '../assets/about-home.png';
import aboutFounderImg from '../assets/about-founder.jpeg';
import addPlaceholder from '../assets/add-placeholder.jpg';
import viewerPlaceholder from '../assets/calsone.jpg';

export const useStrings = {

    /* Start Home Page */
    homeAboutSection: {
        title: "Welcome To Our Pizza Plus",
        description: "OUR PROMISE TO YOU: PIZZA PLUS, strives to maintain the highest standards through all Levels of its business. Proof of this is provided by a number of Independent quality audit standards That we have achieved over Recent years, after meeting exacting criteria and successfully Passing rigorous inspection programmes. Our aim is to consistently Provide our customers and their consumers with excellent quality Products.",
        image: aboutImg,
        backgroundImage: aboutImgBackground,
        buttonText: "Read More...",
        buttonLink: "/about",
    },

    homeCarousel: {
        adminPlaceholder: addPlaceholder,
        viewerPlaceholder: viewerPlaceholder,
    },

    homeGallerySection: {
        title: "Pizza Gallery",
        backgroundColor: "#9a0000",
        adminPlaceholder: addPlaceholder,
    },

    homeFeedbackSection: {
        title: "What People Say",
        backgroundColor: "#9a0000",
        adminPlaceholder: addPlaceholder,
    },

    homeOfferSection: {
        title: "Special Offer On Yummy Pizza",
    },
    /* End Home Page */

    /* Start Banners */
    bannerGallery: {
        adminPlaceholder: addPlaceholder,
        viewerPlaceholder: viewerPlaceholder,
        title: 'Gallery',
    },

    bannerAbout: {
        adminPlaceholder: addPlaceholder,
        viewerPlaceholder: viewerPlaceholder,
        title: 'About',
    },

    bannerProduct: {
        adminPlaceholder: addPlaceholder,
        viewerPlaceholder: viewerPlaceholder,
        title: 'Product',
    },

    bannerContact: {
        adminPlaceholder: addPlaceholder,
        viewerPlaceholder: viewerPlaceholder,
        title: 'Contact',
    },

    bannerMenu: {
        adminPlaceholder: addPlaceholder,
        viewerPlaceholder: viewerPlaceholder,
        title: 'Menu',
    },
    /* End Banners */

    /* About Page */
    AboutStorySection: {
        title: "Our Story",
        description: "Pizza Plus Company. is a Lebanese owned family restaurant founded in 1990. After decades of years, the business is still growing from strength to strength. Pizza Plus Company's excellent reputation is owed to Ali and Hikmat who have been passionately working together in the hospitality industry for the past 32 years. Their talents have led them to many chef positions in Europe and throughout the Middle East, constantly adding to their global culinary repertoire. They have extensive experience in Pizza making and have trained extensively in Italy where they learnt the secret of making traditional thin crust Italian pizza. Their reliability and trustworthiness has allowed them to build strong relationships with food suppliers throughout Lebanon allowing them to choose the finest and freshest ingredients for their perfect dishes. Pizza Plus Company Restaurant comfortably seats 40 people inside and offers a take away delivery service within 15km radius. Polite staff and high quality service adds to the Pizza Plus experience and sets a lively yet relaxing atmosphere where you will never feel rushed to finish your meal. The constantly high standards of food provided at affordable prices are one of the secrets to Pizza Plus Company. Ali and Hikmat’s, the traditional handmade thin crust pizza is a popular choice with locals. Pizza Plus Company is also widely respected by customers visiting from further afield, and returning on a regular basis. Their customers often comment on the extensive menu selection, which is complemented by traditional variety of side dishes, such as panache salad and delicious homemade Lebanese dishes….you will be truly spoilt for choice! Whatever your desire, Pizza Plus Company aim to provide total satisfaction for your taste buds and your distinguished dining companions.",
        image: aboutImg,
        backgroundImage: aboutImgBackground,
        buttonText: "1990 - 2024",
    },

    AboutSummarySection: {
        title: "WHO WE ARE",
        description: "Pizza Plus is set at great location, with many shops and coffee Shops around. Beautifully designed, this Pizza Plus restaurant has exterior and ultra-modern décor on and dining areas where you can dine delicious Italian Pizza in style! Think of the best pizzas, and we are ready to serve you just that with warm greetings and smiles. The buzzing atmosphere in here makes this Pizza Plus a perfect spot for a night out with your friends. Whereas, if you are here for a romantic dinner, we have cosy banquette seating areas too! Regarding the food menu, we have great range of gluten free recipes, delicious vegan, vegetarian and lower calorie options, so be assured that both you and your guests will be happy after. Public transports are easily accessible from here. Home delivery is always available at Pizza Plus Restaurant.",
        image: aboutImg,
        backgroundImage: aboutImgBackground,
        buttonText: "1990 - 2024",
    },

    AboutFounderSection: {
        title: "PIZZA PLUS FOUNDER",
        subtitle: "Hakim Brother",
        description: "We Just Love Gluten Free Here at Pizza Plus we take the nations much loved Foods and give them a free from make-over, Without compromising on taste. We aim to Deliver healthier options and free from Alternatives to classic Foods, So you no longer have to miss out on your much-Loved favorites when avoiding the notorious Digestive demon known as gluten ! Pizza Plus products are always gluten-Free and Produced in a gluten free environment. many of Our products are also dairy free and vegan. We have Strong personal Values in this direction and this is where we are Focusing our innovative efforts. We aim to have an appealing range of free From foods to suit Everyone and wherever possible add a healthier twist. We Hope you enjoy browsing through our site !!",
        image: aboutFounderImg,
    },
    /* End About Page*/

    /* Start Product Page */
    ProductPizzaSection: {
        title: "How TO Make Pizza",
        description1: "Pizza: it’s a delicious staple that’s been elevated far beyond its humble roots by Italian restaurants in Beirut and beyond. Always Look for Pizza Plus. and see how we make the pizza for you.",
        description2: "The first Lebanese Restaurant Pizza Plus creates beautifully airy dough that’s fermented for up to 72 hours using a secret recipe. Eaters are actively encouraged to go for the ‘half and half’ pizza, which come as either a Small Size or a Large Size, choosing from a range of high-quality artisanal toppings and for no extra cost. As artichokes and Takeaways and local delivery available",
        adminPlaceholder: addPlaceholder,
    },

    ProductSauceSection: {
        title: "How TO Make Sauce",
        description1: "Look for the sauce from Pizza Plus. and enjoy the taste of it.",
        description2: "Homemade pizza sauce With your partially pre-baked crust, you have the perfect canvas for a delicate, fresh pizza sauce that can be thrown together in a blender. our cheff came up with this delicious recipe, and we usually make it in large batches, so we can freeze a bunch of 1-cup servings. perfect for one pizza. This way we always have homemade pizza sauce on hand. Yummy Yummy!!!",
        adminPlaceholder: addPlaceholder,
    },
     /* End Product Page */

     /* Start Contact Page */
     ContactFormSection: {
        title: "Keep In Touch With Us",
        subtitle: "Fill out the form below to reach out to our Support team. We aim to respond as quickly as possible",
    },

    ContactRestaurantDetails: {
        addressLabel: "Visit Us:",
        addressValue: "Sayed Hadi Nassrallah Highway Facing the Bridge Shaheen Bldg Ground Floor,Next to Lycee De La Finesse School, Dahye Rouweys Haret Hreik, Lebanon Mount Baabda.",
        emailLabel: "Email Us:",
        emailValue: "contact@pizzapluscompany.com",
        phoneLabel: "Call Us:",
        phoneValue: "+961(0)1-27 55 35",
        whatsAppLabel: "Whatsapp:",
        whatsAppValue: "+9613940636",
        workingHoursLabel: "working Hours:",
        workingHoursValue: "Every Day 10:00 AM to 01:00 AM",
    },

    ContactAboutSection: {
        title: "Keep In Touch With Us",
        description: "Neapolitan pizza is so good that it has recently become protected by UNESCO – but luckily for us, many Italians have flown the nest to bring Lebanese a taste of their patrimony. We’ve found the best spots for a good pizza in Beirut, from Pizza Plus, from the traditional to green pizza bases and fully-vegan options. Buon appetito",
        description2: "(OR) ORDER THROUGH ONE PHONE CALL +961(0) 1-27 55 35",
        buttonText: "Order Now",
        buttonLink: "#",
    },
     /* End Contact Page */

     /* Start Main Footer */
     MainFooter: {
        copyrightText: `© ${new Date().getFullYear()} Pizza Plus. All rights reserved.`,
        addressLabel: "Address:",
        phoneLabel: "Phone:",
        workingHoursLabel: "working Hours:",
        contactInfoTitle: "Contact Info",
        aboutDescription: "We should say up front, Top Pizza Plus is not messing around. Their tomatoes? Grown in the foothills of Mount Vesuvius. The oven? Heated to 400oC. The Sauce? Homemade. You don't mess around and neither should your favourite slice. To top it all off, all the pizzas are handmade to order from scratch and served a stone's throw from the beach.",
        quickLinksTitle: "Quick Links",
    },
     /* End Main Footer */

     /* Branches Codes */
     branchesCode: {
        haretHreikBranchCode: "haret_hreik",
        centroMallBranchCode: "centro_jneh"
     }
     /* End Branches Codes */


}