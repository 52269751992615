import { useState, useCallback, useEffect } from "react";

import { config } from "../../config";
import { useHttpClient } from "../Hooks/http-hook";

const useSections = () => {
    const [sections, setSections] = useState([]);  // State to store sections data
    const [isUpdatedSection, setIsUpdatedSection] = useState(false);
    const [HideSkeleton, setHideSkeleton] = useState(false);
    const { sendRequest, error, clearError } = useHttpClient();

    const updateSections = (newSections) => {
        setSections(newSections);
        setIsUpdatedSection(true);
    };

    // Function to get an object by property name
    const getSectionByName = useCallback((propertyName, propertyValue) => {
        // Find the first object in the array where the specified property has the given value
        const foundObject = sections.find(
            (section) => section && section[propertyName] === propertyValue
        );

        // Return the found object, or null if not found
        return foundObject || null;
    }, [sections]);

    const fetchSections = useCallback(async () => {
        try {
            const responseData = await sendRequest(
                `${config.API_URL}/sections`
            );
            updateSections(responseData);
            clearError();
        } catch (err) {
            setIsUpdatedSection(true);
            console.error(err);
        }
    }, [sendRequest, updateSections, isUpdatedSection, clearError]);

    useEffect(() => {
        fetchSections();
        setTimeout(() => {
            setHideSkeleton(true);
        }, 1000);
    }, []);

    return {
        sections,
        updateSections,
        isUpdatedSection,
        getSectionByName,
        fetchSections,
        HideSkeleton,
        sectionError: error
    }
;
}

export default useSections;