import { useState, useCallback, useEffect } from "react";

import { config } from "../../config";
import { useHttpClient } from "../Hooks/http-hook";

const useFeedbacks = () => {
    const [feedbacks, setFeedbacks] = useState();  // State to store feedbacks data
    const [isUpdatedFeedbacks, setIsUpdatedFeedbacks] = useState(false);
    const [HideFeedbacksSkeleton, setHideFeedbacksSkeleton] = useState(false);
    const { error, sendRequest, clearError } = useHttpClient();

    const updateFeedbacks = (newFeedbacks) => {
        setFeedbacks(newFeedbacks);
        setIsUpdatedFeedbacks(true);
    };

    const isEmptyFeedbacks = useCallback(() => {
        return feedbacks?.length > 0;
    }, [feedbacks]);

    const fetchFeedbacks = useCallback(async () => {
        try {
            const responseData = await sendRequest(
                `${config.API_URL}/feedbacks`
            );
            clearError();
            updateFeedbacks(responseData);
        } catch (err) {
            setIsUpdatedFeedbacks(true);
            console.error(err);
        }
    }, [sendRequest, updateFeedbacks, isUpdatedFeedbacks]);

    const adminFetchFeedbacks = useCallback(async (status, auth) => {
        try {
            const responseData = await sendRequest(
                `${config.API_URL}/feedbacks/${status}`,
                'GET',
                null,
                {
                    Authorization: 'Bearer ' + auth.token
                }
            );
            clearError();
            updateFeedbacks(responseData);
        } catch (err) {
            setIsUpdatedFeedbacks(true);
            throw err
        }
    }, [sendRequest, updateFeedbacks, setIsUpdatedFeedbacks]);

    const FeedbackDeletedHandler = useCallback(deletedFeedbackId => {
        setFeedbacks(prevFeedbacks => prevFeedbacks.filter(feedback => feedback._id !== deletedFeedbackId));
    }, [setFeedbacks]);

    return {
        feedbacks,
        updateFeedbacks,
        isUpdatedFeedbacks, 
        fetchFeedbacks,
        HideFeedbacksSkeleton,
        isEmptyFeedbacks,
        FeedbackDeletedHandler,
        FeedbacksError: error,
        adminFetchFeedbacks,
        setHideFeedbacksSkeleton,
    };
}

export default useFeedbacks;