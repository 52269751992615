import { useState, useCallback, useEffect } from "react";

import { config } from "../../config";
import { useHttpClient } from "../Hooks/http-hook";
import { useStrings } from "../strings/stringsConfig";

const useCategories = () => {
    const [categories, setCategories] = useState();  // State to store categories data
    const [isUpdatedCategories, setIsUpdatedCategories] = useState(false);
    const [HideCategoriesSkeleton, setHideCategoriesSkeleton] = useState(false);
    const { error, sendRequest, clearError } = useHttpClient();

    const ImageDeletedHandler = useCallback((deletedImageIndex, productIndex, categoryIndex) => {
        setCategories(prevProducts => {
            const updatedCategories = [...prevProducts.categories]; // Create a new array to avoid mutation
            const updatedImages = [...updatedCategories[categoryIndex]?.items[productIndex].images]; // Create a new array for the items
    
            // Filter out the deleted image
            const filteredImages = updatedImages.filter((image, imageIdx) => imageIdx !== deletedImageIndex);
    
            // Update the items array for the specific category
            const updatedItems = [...updatedCategories[categoryIndex].items]; // Create a new array for the items
            updatedItems[productIndex] = {
                ...updatedItems[productIndex],
                images: filteredImages
            };
    
            // Update the specific category
            updatedCategories[categoryIndex] = {
                ...updatedCategories[categoryIndex],
                items: updatedItems
            };
    
            // Return the updated state
            return {
                ...prevProducts,
                categories: updatedCategories
            };
        });
    }, [setCategories]);

    const updateCategories = (newCategories) => {
        setCategories(newCategories);
        setIsUpdatedCategories(true);
    };

    const isEmptyItems = useCallback((categories) => {
        for (const category of categories) {
            if (category.items.length > 0) {
                return false;
            }
        }
        return true;
    }, []);

    const isEmptyImages = useCallback((categories) => {
        for (const category of categories) {
            for (const item of category.items) {
                for (const imageObj of item.images) {
                    if (imageObj.image) {
                        return false;
                    }
                }
            }
        }
        return true;
    }, []);

    const fetchCategories = useCallback(async () => {
        try {
            const responseData = await sendRequest(
                `${config.API_URL}/menu/categories`
            );
            clearError();
            updateCategories(responseData.menu);
        } catch (err) {
            setIsUpdatedCategories(true);
            console.error(err);
        }
    }, [sendRequest, updateCategories, isUpdatedCategories, clearError]);

    const CategoryDeletedHandler = useCallback(deletedCategoryId => {
        setCategories(prevCategories => {
            return {
                ...prevCategories,
                categories: prevCategories.categories.filter(category => category.id !== deletedCategoryId)
            };
        });
    }, [setCategories]);

    const ProductDeletedHandler = useCallback((deletedProductId, categoryIndex) => {
        setCategories(prevProducts => {
            const updatedCategories = [...prevProducts.categories]; // Create a new array to avoid mutation
            const updatedItems = [...updatedCategories[categoryIndex].items]; // Create a new array for the items

            // Filter out the deleted product
            const filteredItems = updatedItems.filter(product => product.id !== deletedProductId);

            // Update the items array for the specific category
            updatedCategories[categoryIndex] = {
                ...updatedCategories[categoryIndex],
                items: filteredItems
            };

            // Return the updated state
            return {
                ...prevProducts,
                categories: updatedCategories
            };
        });
    }, [setCategories]);

    const getGalleryHomeItem = useCallback(() => {
        let items = [];
        if (!categories) return items;
    
        for (const category of categories?.categories) {
            if (category.branchCode === useStrings.branchesCode.haretHreikBranchCode) {
                for (const product of category?.items) {
                    for (const image of product?.images) {
                        if (image.image) {
                            items.push(image);
                        }
                        if (items.length > 7) return items;
                    }
                }
            }
        }
        return items; // Make sure to return the items array if it hasn't reached length > 7
    }, [categories]);

    return {
        categories,
        updateCategories,
        isUpdatedCategories,
        fetchCategories,
        HideCategoriesSkeleton,
        isEmptyItems,
        isEmptyImages,
        ImageDeletedHandler,
        CategoryDeletedHandler,
        ProductDeletedHandler,
        categoriesError: error,
        getGalleryHomeItem,
        setHideCategoriesSkeleton
    };
}

export default useCategories;