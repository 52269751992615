import { createContext, useContext } from "react";

export const RestaurantDetailsContext = createContext({
  restaurantDetails: [],
  fetchRestaurantDetails: () => {},
  HideRestaurantDetailsSkeleton: true,
  isUpdatedRestaurantDetails: false,
  RestaurantDetailsError: null,
});

export const useRestaurantDetailsContext = () => useContext(RestaurantDetailsContext);
