import { createContext, useContext } from "react";

export const CategoriesContext = createContext({
  categories: [],
  updateCategories: () => {},
  ImageDeletedHandler: () => {},
  isEmptyItems: () => {},
  isEmptyImages: () => {},
  fetchCategories: () => {},
  CategoryDeletedHandler: () => {},
  ProductDeletedHandler: () => {},
  HideCategoriesSkeleton: true,
  isUpdatedCategories: false,
  categoriesError: null,
  getGalleryHomeItem: [],
  setHideCategoriesSkeleton: () => {}
});

export const useCategoriesContext = () => useContext(CategoriesContext);
