import { createContext, useContext } from "react";

export const OffersContext = createContext({
    offers: [],
    updateOffers: () => { },
    isEmptyOffers: () => { },
    fetchOffers: () => { },
    OfferDeletedHandler: () => { },
    HideOffersSkeleton: true,
    isUpdatedOffers: false,
    OffersError: null,
    setHideOffersSkeleton: () => {}
});

export const useOffersContext = () => useContext(OffersContext);
