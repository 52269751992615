import { createContext, useContext } from "react";

export const SectionsContext = createContext({
  sections: [],
  updateSections: () => {},
  getSectionByName: () => {},
  isUpdatedSection: false,
  sectionError: null,
  hideSectionSkeleton: false,
});

export const useSectionsContext = () => useContext(SectionsContext);
