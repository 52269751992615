import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import { AuthContext } from '../../context/auth-context';
import './NavLinks.css';

const NavLinks = props => {
  const auth = useContext(AuthContext);
  return <ul className={`nav-links ${props.classes ? props.classes : 'default'}`}>
    <li>
      <NavLink to="/" exact>Home</NavLink>
    </li>
    <li>
      <NavLink to="/gallery">Gallery</NavLink>
    </li>
    <li>
      <NavLink to="/product">Product</NavLink>
    </li>
    <li>
      <NavLink to="/menu">Menu</NavLink>
    </li>
    <li>
      <NavLink to="/about">About us</NavLink>
    </li>
    <li>
      <NavLink to="/contact">Contact</NavLink>
    </li>
    { auth.role === 'admin' && props.showLogout &&
      <li>
        <button className='logout-btn' onClick={auth.logout}>Logout</button>
      </li>
    }
  </ul>
};

export default NavLinks;