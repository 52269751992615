import React from 'react';

import logo from '../../assets/cropped-logo.png';
import NavLinks from '../Navigation/NavLinks';
import { faFacebook, faTwitter, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRestaurantDetailsContext } from '../../context/restaurant-details-context';
import { useStrings } from '../../strings/stringsConfig';
import Skeleton from '../Skeleton/skeleton';

import './MainFooter.css';

const MainFooter = props => {
    const {
        restaurantDetails,
        HideRestaurantDetailsSkeleton,
        isUpdatedRestaurantDetails,
    } = useRestaurantDetailsContext();
 
    return (
        <footer className="main-footer">
            <div className="footer-upper-container">
                <div className='footer-about-image-container'>
                    <img src={logo} />
                </div>
                <p>
                    {useStrings.MainFooter.aboutDescription}
                </p>
            </div>
            <div className="footer-upper-container text-center full-width margin-forty">
                <p className='title'>{useStrings.MainFooter.quickLinksTitle}</p>
                <NavLinks showLogout={false} classes="footer-links" />
            </div>
            {(!isUpdatedRestaurantDetails || !HideRestaurantDetailsSkeleton) &&
                    <Skeleton mainFooterContactSkeleton/>
            }
            {(isUpdatedRestaurantDetails && HideRestaurantDetailsSkeleton) &&
                <div className="footer-upper-container margin-forty">
                    <p className='title'>{useStrings.MainFooter.contactInfoTitle}</p>
                    <div className='contact-info-content'>
                        <p className='bold'>
                            {useStrings.MainFooter.addressLabel}
                        </p>
                        <p>
                            {restaurantDetails?.address || useStrings.ContactRestaurantDetails.addressValue}
                        </p>
                    </div>

                    <div className='contact-info-content'>
                        <p className='bold'>
                            {useStrings.MainFooter.phoneLabel}
                        </p>
                        <p>
                            {restaurantDetails?.phone || useStrings.ContactRestaurantDetails.phoneValue}
                        </p>
                    </div>

                    <div className='contact-info-content'>
                        <p className='bold'>
                            {useStrings.MainFooter.workingHoursLabel}
                        </p>
                        <p>
                            {restaurantDetails?.workingHours || useStrings.ContactRestaurantDetails.workingHoursValue}
                        </p>
                    </div>
                </div>
            }
            <hr />
            <div className="footer-lower-container">
                <div className="icons">
                    <div>
                        <a href="https://www.facebook.com/pizzaplus.lb/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faFacebook} className="facebook-icon icon" />
                        </a>
                    </div>
                    <div>
                        <a href="https://twitter.com/pizzaplus_lb" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faTwitter} className="twitter-icon icon" />
                        </a>
                    </div>
                    <div>
                        <a href="https://www.linkedin.com/in/pizza-plus435b6b185/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faLinkedin} className="linkedin-icon icon" />
                        </a>
                    </div>
                    <div>
                        <a href="https://www.instagram.com/pizzaplus.lb/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram} className="instagram-icon icon" />
                        </a>
                    </div>
                </div>
                <p>{useStrings.MainFooter.copyrightText}</p>
            </div>
        </footer>
    );
}

export default MainFooter;