import React from "react";

import './skeleton.css';

const categoriesSkeleton = props => {
    let content = (
        <div className="categories-skeleton-container">
            <div className="categories-skeleton"></div>
            <div className="categories-skeleton"></div>
            <div className="categories-skeleton"></div>
            <div className="categories-skeleton"></div>
        </div>);
    return content;
};

const categoriesProductsSkeleton = props => {
    let content = (
        <div className="categories-products-skeleton-container">
            <div className="categories-products-skeleton"></div>
            <div className="categories-products-skeleton"></div>
            <div className="categories-products-skeleton"></div>
        </div>);
    return content;
};

const galleryProductsSkeleton = props => {
    let content = (
        <div className="gallery-products-skeleton-container">
            <div className="gallery-products-skeleton"></div>
        </div>);
    return content;
};

const homeAboutSkeleton = props => {
    let content = (
        <div className="home-about-skeleton-container">
            <div className="home-about-image-skeleton"></div>
            <div className="home-about-details-skeleton">
                <div className="home-about-title-skeleton"> </div>
                <div className="home-about-description-skeleton"></div>
                <div className="home-about-description-skeleton"></div>
                <div className="home-about-description-skeleton"></div>
                <div className="home-about-button-skeleton"></div>
            </div>
        </div>
    );
    return content;
};

const homeCarouselSkeleton = props => {
    let content = (
        <div className="home-carousel-skeleton-container">
        </div>
    );
    return content;
};

const bannerSkeleton = props => {
    let content = (
        <div className="banner-skeleton-container">
        </div>
    );
    return content;
};

const productSectionSkeleton = props => {
    let content = (
        <div className="product-skeleton-container">
            <div className="product-details-skeleton">
                <div className="product-title-skeleton"> </div>
                <div className="product-description-skeleton"></div>
                <div className="product-description2-skeleton"></div>
                <div className="product-description2-skeleton"></div>
                <div className="product-description2-skeleton"></div>
            </div>
        </div>
    )
    return content;
}

const galleryHomeAdminSkeleton = props => {
    let content = (
        <div className="gallery-home-admin-container">
            <div className="section-wrap-skeleton">
                <div className="section-title-skeleton"> </div>
                <div className="section-picker-skeleton"></div>
                <div className="section-available-skeleton"></div>
                <div className="section-submit-skeleton"></div>
            </div>
        </div>
    )
    return content;
}

const feedbackHomeAdminSkeleton = props => {
    let content = (
        <div className="feedback-home-admin-container">
            <div className="section-wrap-skeleton">
                <div className="section-title-skeleton"> </div>
                <div className="section-available-skeleton"></div>
                <div className="section-submit-skeleton"></div>
            </div>
        </div>
    )
    return content;
}

const feedbackHomeSkeleton = props => {
    let content = (
        <div className="feedback-home-container">
            <div className="feedback-upper-container">
                <div className="feedback-title-skeleton"> </div>
                {props?.auth.role === "admin" &&
                    <div className="feedback-add-skeleton"></div>
                }
            </div>
            <div className="feedback-items-container">
                <div className="feedback-item-skeleton"> </div>
                <div className="feedback-item-skeleton hidden"> </div>
            </div>
        </div>
    )
    return content;
}

const contactSectionAdminSkeleton = props => {
    let content = (
        <div className="contact-home-admin-container">
            <div className="section-wrap-skeleton">
                <div className="section-title-skeleton"> </div>
                <div className="section-subtitle-skeleton"> </div>
                <div className="section-available-skeleton"></div>
                <div className="section-submit-skeleton"></div>
            </div>
        </div>
    )
    return content;
}

const contactSectionSkeleton = props => {
    let content = (
        <div className="contact-section-container">
            <div className="contact-upper-container">
                <div className="contact-title-skeleton"> </div>
                <div className="contact-subtitle-skeleton"> </div>
            </div>
            <div className="contact-bottom-container">
                <div className="contact-left-container">
                    <div className="contact-wrap-container">
                        <div className="contact-long-skeleton"></div>
                        <div className="contact-short-skeleton"></div>
                    </div>
                    <div className="contact-wrap-container">
                        <div className="contact-long-skeleton"></div>
                        <div className="contact-short-skeleton"></div>
                    </div>
                    <div className="contact-wrap-container">
                        <div className="contact-long-skeleton"></div>
                        <div className="contact-short-skeleton"></div>
                    </div>
                    <div className="contact-wrap-container">
                        <div className="contact-long-skeleton"></div>
                        <div className="contact-short-skeleton"></div>
                    </div>
                </div>
                <div className="contact-right-container">
                    <div className="contact-wrap-container">
                        <div className="contact-long-skeleton"></div>
                        <div className="contact-short-skeleton"></div>
                    </div>
                    <div className="contact-wrap-container">
                        <div className="contact-long-skeleton"></div>
                        <div className="contact-short-skeleton"></div>
                    </div>
                    <div className="contact-wrap-container">
                        <div className="contact-long-skeleton"></div>
                        <div className="contact-short-skeleton"></div>
                    </div>
                    <div className="contact-wrap-container">
                        <div className="contact-long-skeleton"></div>
                        <div className="contact-short-skeleton"></div>
                    </div>
                </div>
            </div>

        </div>
    )
    return content;
}

const mainFooterContactSkeleton = props => {
    let content = (
        <div className="main-footer-skeleton-container">
            <div className="footer-contact-wrap-skeleton">
                <div className="footer-contact-text-skeleton"></div>
                <div className="footer-contact-text-skeleton full-width"></div>
                <div className="footer-contact-text-skeleton"></div>
                <div className="footer-contact-text-skeleton full-width"></div>
                
            </div>
        </div>
    )
    return content;
}

const Skeleton = props => {
    return (
        <React.Fragment>
            {props.categorySkeleton &&
                categoriesSkeleton()
            }
            {props.categoriesProductsSkeleton &&
                categoriesProductsSkeleton()
            }
            {props.galleryProductsSkeleton &&
                galleryProductsSkeleton()
            }
            {props.homeAboutSkeleton &&
                homeAboutSkeleton()
            }
            {props.homeCarouselSkeleton &&
                homeCarouselSkeleton()
            }
            {props.bannerSkeleton &&
                bannerSkeleton()
            }
            {props.productSectionSkeleton &&
                productSectionSkeleton()
            }
            {props.galleryHomeAdminSkeleton &&
                galleryHomeAdminSkeleton()
            }
            {props.feedbackHomeAdminSkeleton &&
                feedbackHomeAdminSkeleton()
            }
            {props.feedbackHomeSkeleton &&
                feedbackHomeSkeleton(props)
            }
            {props.contactSectionAdminSkeleton &&
                contactSectionAdminSkeleton()
            }
            {props.contactSectionSkeleton &&
                contactSectionSkeleton(props)
            }
            {props.mainFooterContactSkeleton &&
                mainFooterContactSkeleton(props)
            }

        </React.Fragment>

    );
}

export default Skeleton;